<template>
  <div class="credits-card" :style="`--texture:url(${cloudfront(texture)});`">
    <div class="credits-card-content">
      <div class="credits-card-top">
        <div class="title">{{ $locale["object"]["user-profile"]["available-credits-label"] }}</div>
        <div>
          <div class="logo"><iconic name="mf_logo" /></div>
          <div class="user">@{{ $user.user }}</div>
        </div>
      </div>
      <div class="credits-card-bottom">
        <div class="credits-card-balance" flex>
          <div class="icon"><iconic name="coins" /></div>
          <div class="num">
            <div class="number">{{ $profile.balance }}</div>
            <div class="label">{{ $locale["object"]["user-profile"]["credits-label"] }}</div>
          </div>
        </div>
        <div class="options">
          <a class="options-button" @click="modalNav($links['my-credits'])" v-if="modal"><iconic name="plus" /></a>
          <a class="options-button" @click="context"><iconic name="ellipsis-v" /></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["user", "locale", "profile", "modal"],
  data: function() {
    return {
      texture: "https://myfans1a.s3.amazonaws.com/credits_card/texture.png",
    };
  },
  methods: {
    context: function() {
      this.menuComponent("CreditsCardMenu", { cardModal: this.modal });
    },
  },
};
</script>

<style lang="scss">
.credits-card {
  position: relative;
  background-color: $primary_color;
  box-shadow: 2px 2px 8px rgba(69, 0, 0, 0.8);
  border-radius: $mpadding;
  padding: $mpadding * 2;
  overflow: hidden;
  &-content {
    color: #fff;
  }
  &::before {
    content: "";
    position: absolute;
    width: $mobile_s_width;
    height: $mobile_s_width;
    top: -10%;
    left: 40%;
    background: #44444431;
    border-radius: 50%;
    pointer-events: none;
  }
  &::after {
    content: "";
    position: absolute;
    background-image: var(--texture);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.8;
  }
  &-top,
  &-bottom {
    position: relative;
    z-index: 1;
  }
  &-top {
    @include Flex(inherit, space-between, inherit);
    .title {
      margin: $mpadding/2 0 0 0;
    }
    .logo {
      text-align: right;
      .iconic svg {
        width: 100px;
        height: auto;
      }
    }
    .user {
      text-align: right;
      font-size: 90%;
    }
  }
  &-bottom {
    @include Flex(inherit, space-between, flex-end);
    margin: $mpadding * 3 0 0 0;
    .options {
      display: flex;
      align-items: center;
      gap: $mpadding;
      &-button {
        width: $mpadding * 2.5;
        height: $mpadding * 2.5;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 50%;
        &:hover {
          background: #eeeeee46;
        }
      }
    }
  }
  &-balance {
    .icon {
      margin: 0 $mpadding/2 0 0;
      font-size: 180%;
      .iconic {
        @include Flex(inherit, center, center);
      }
    }
    .number {
      font-size: 32px;
      font-weight: bold;
    }
    .label {
      margin: 0;
    }
  }
  @media (min-width: $tablet_width) {
    &::before {
      top: -10%;
      left: 60%;
    }
    &-bottom {
      margin: $mpadding * 4 0 0 0;
    }
  }
}
</style>
